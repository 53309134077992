<template>
  <div class="homepage">
    <Header :type="this.headerTypes.LANDING" :title="$t('Landing header title')"
            :description="$t('Landing header description')"/>
    <div class="rf-accomodation">
      <b-container>
        <b-row class="mt-5">
          <b-col cols="12" md="6" class="position-relative">
            <h1 class="accomodation-title">{{$t('Accommodation')}}</h1>
            <div class="accomodation-text">
              <p class="text-line">{{$t('We have the perfect RooF for you.')}}</p>
              <p class="text-line">{{$t('See our listings for more details on available accomodation or tell us what you are looking for and we\'ll be glad to help you')}}</p>
            </div>
            <div class="accommodation-texture first d-none d-md-block"/>
            <div class="accommodation-texture d-none d-md-block"/>
          </b-col>
          <b-col cols="12" md="6" class="mt-5 mt-md-0 px-0 pr-md-3">
            <div class="booking-form-container">
              <ValidationObserver ref="validator">
                <form class="booking-form" @submit.prevent="search">
                  <b-row class="d-flex justify-content-between align-items-center">
                    <b-col cols="12" lg="4">
                      <div class="booking-label">{{$t('When do you need it?')}}</div>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <RFValidation rules="required">
                        <RFMonthPicker v-model="searchParams.startDate" :min="currentMonth" :special-format="specialDateFormat" />
                      </RFValidation>
                    </b-col>
                  </b-row>
                  <b-row class="d-flex justify-content-between align-items-center">
                    <b-col cols="12" lg="4">
                      <div class="booking-label">{{$t('For how many months?')}}</div>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <RFValidation rules="required">
                        <RFInput type="number" :min="1" v-model="searchParams.months" background="#fff"/>
                      </RFValidation>
                    </b-col>
                  </b-row>
                  <b-row class="d-flex justify-content-between align-items-center">
                    <b-col cols="12" lg="4">
                      <div class="booking-label">{{$t('Type of apartment?')}}</div>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <RFSelect :list="categoriesList" v-model="searchParams.categoryId" />
                    </b-col>
                  </b-row>
                  <b-row class="d-flex justify-content-between align-items-center mb-5">
                    <b-col cols="12" lg="4">
                      <div class="booking-label">{{$t('Estimated budget?')}}</div>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <RFCurrencyInput v-model="searchParams.price" background="#fff"/>
                    </b-col>
                  </b-row>
                  <b-row class="position-relative z-10">
                    <b-col>
                      <RFButton class="my-5" :label="$t('Roof Me')" full-width background="#74776D" color="#fff" border-color="transparent"/>
                    </b-col>
                  </b-row>
                </form>
              </ValidationObserver>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="rf-explore">
      <h1 class="explore-text">{{`...${$t('Or explore all our options')}!`}}</h1>
    </div>
    <div class="rf-type">
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="6" class="rf-accomodation apartments mb-5 mb-md-0 px-0">
            <div class="rf-apartments">
              <h1 class="title">{{$t('Apartments')}}</h1>
              <router-link :to="{ name: 'apartments-page' }">
                <RFButton class="px-5" :label="$t('Discover')" background="#67AEA6" color="#fff" border-color="transparent"/>
              </router-link>
            </div>
          </b-col>
          <b-col  cols="12" md="6" class="rf-accomodation buildings px-0">
            <div class="rf-buildings">
              <h1 class="title">{{$t('RooF Buildings')}}</h1>
              <router-link to="/buildings">
                <RFButton class="px-5" :label="$t('Discover')" background="#E26F5A" color="#fff" border-color="transparent"/>
              </router-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="rf-icons mt-5">
     <img class="w-100" src="@/assets/images/home_rf_icons.jpg">
    </div>
    <div class="rf-services" id="services">
      <b-container>
        <b-row class="justify-content-center">
          <b-col cols="12" md="6" class="text-center">
            <h1 class="title">{{$t('Services')}}</h1>
            <p class="text">
              {{$t('Landing services description')}}
            </p>
          </b-col>
        </b-row>
        <b-row class="my-5 justify-content-center">
          <b-col class="text-center mb-3" cols="4" lg="1" v-for="(service, index) in services" :key="`service-${index}`">
            <img class="service-image" :src="`/images/rf-services/${service.image}.png`" />
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <b-col cols="auto">
            <a href="https://wa.me/393518897372" target="_blank">
              <RFButton :label="$t('Tell me more')" background="#24425B" color="#fff"/>
            </a>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="rf-roof-world" id="roofWorld">
      <b-container>
        <b-row class="justify-content-center">
          <b-col cols="12" md="6" class="text-center">
            <img class="rfw-image" src="@/assets/images/rf_logo.png">
            <p class="text1">
              {{$t('Roof World description 1',{ RoofWorldTitle: $t('Roof World') })}}
            </p>
            <p class="text2">
              {{$t('Roof World description 2')}}
            </p>
              <a href="https://www.roofindersworld.com/">
                <RFButton :label="$t('Discover More')" background="#fff" color="#24425B"/>
              </a>
            <!-- <router-link to="/">
              <RFButton label="Read" background="#24425B" color="#fff"/>
            </router-link> -->
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import RFInput from '@/components/forms/RFInput'
import RFCurrencyInput from '@/components/forms/RFCurrencyInput'
import RFButton from '@/components/forms/RFButton'
import RFMonthPicker from '@/components/forms/RFMonthPicker'
import RFSelect from '@/components/forms/RFSelect'
import RFValidation from '@/components/forms/RFValidation'

import { capitalize } from '@/helpers/filters'
import { calculateMinStay } from '@/helpers/functions'
import { mapState } from 'vuex'
import { headerTypes,accommodations } from '@/enums'

import moment from 'moment'

export default {
  name: 'Homepage',
  components: {
    Header,
    RFInput,
    RFCurrencyInput,
    RFButton,
    RFMonthPicker,
    RFSelect,
    RFValidation,
  },
  data() {
    return {
      headerTypes,
      headerTypesList : [],
      categoriesList: [],
      searchParams: {
        startDate: null,
        months: null,
        categoryId: null,
        price: null,
      },
      specialDateFormat: true,
      services: [
        { image: 'airport' },
        { image: 'atm' },
        { image: 'bike' },
        { image: 'gym' },
        { image: 'lessons' },
        { image: 'paperwork' },
        { image: 'sim' },
      ]
    }
  },
  computed: {
    ...mapState({
      categories: state => state.categoryStore.categories
    }),
    currentMonth() {
      return moment().format('YYYY-MM')
    }
  },
  async beforeMount() {
    await this.$store.dispatch('categoryStore/getCategories')
    this.categoriesList = this.categories.map(type => ({ value: type.id, label: type.name }))
    await this.$store.dispatch('accommodationTypeStore/getAccommodationTypes')
  },
  methods: {
    async search() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.$store.commit('searchStore/setParams', this.searchParams)
        this.$router.push({ name: 'search-page' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.homepage {
  .rf-accomodation {
    position: relative;

    .accomodation-title {
      @include font-style($montserrat-bold, 'bold', $font-26);
      color: $dark-blue;
    }
    .accomodation-text {
      margin-top: 40px;

      .text-line {
        @include font-style($montserrat, 'medium', $font-16);
        color: $dark-blue;
        margin: 0;
      }
    }
    .accommodation-texture {
      width: 100vw;
      background-image: url('/images/gray_texture.png');
      background-repeat: repeat-x;
      position: absolute;
      height: 200px;
      bottom: 0;
      z-index: 5;

      &.first {
        left: -100vw;
      }
    }
    .booking-form-container {
      background-color: $dark-blue;
      padding: 15px;
      position: relative;
      z-index: 5;

      &:after,
      &:before {
        content: '';
        width: 100vw;
        height: 100%;
        background-color: $dark-blue;
        position: absolute;
        top: 0;
        z-index: -1;
      }
      &:before {
        right: 50%;
      }
      &:after {
        left: 50%;
      }

      .booking-label {
        @include font-style($montserrat-bold, 'bold', $font-14);
        color: $white;
        margin-top: 20px;
        white-space: nowrap;
      }
    }
  }
  .rf-explore {
    background: $gray;
    padding: 30px 0;

    .explore-text {
      @include font-style($montserrat-bold, 'bold', $font-20);
      text-align: center;
      margin: 0;
      color: $dark-blue;
    }
  }
  .rf-type {
    position: relative;

    .rf-accomodation {
      height: 50vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.apartments {
        background-image: url('/images/A-image.jpg');
        border-bottom: 10px solid $green;

        .rf-apartments {
          text-align: center;
        }
      }
      &.buildings {
        background-image: url('/images/R-image.jpg');
        border-bottom: 10px solid $orange;

        .rf-buildings {
          text-align: center;
        }
      }
    }
    .title {
      @include font-style($montserrat-bold, 'bold', $font-24);
      color: $white;
    }
  }
  .rf-services {
    padding: 100px 0;

    .title {
      @include font-style($montserrat-bold, 'bold', $font-26);
      color: $dark-blue;
      margin-bottom: 50px;
    }
    .text {
      @include font-style($montserrat, 'medium', $font-16);
      color: $dark-blue;
    }
    .service-image {
      width: 60%;
    }
  }
  .rf-roof-world {
    background-image: url('/images/home_roof_world.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 70vh;
    display: flex;
    align-items: center;

    .text1 {
      @include font-style($montserrat, 'medium', $font-16);
      color: $white;
      margin-top: 50px;
    }
    .text2 {
      @include font-style($montserrat, 'medium', $font-16);
      color: $white;
    }
    .rfw-image {
      width: 100%;
    }
    @media screen and (max-width: 800px) {
      .rfw-image {
        width: 60%;
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .homepage {
    .rf-accomodation {
      .accomodation-text {
        width: 80%;
      }
      .booking-form-container {
        padding: 50px 0 50px 50px;
        position: static;
        z-index: 0;

        &:before {
          display: none;
        }
        &:after {
          display: block;
        }
        .booking-form {
          position: relative;
          z-index: 10;
        }
      }
    }
    .rf-type {
      .rf-accomodation {
        height: 60vh;

        &.buildings,
        &.apartments {
          // align-items: flex-end;

          .rf-buildings,
          .rf-apartments {
            padding: 0px;
            text-align: center;
          }
        }
      }
    }
    .rf-services {
      .service-image {
        width: 40%;
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .homepage {
    .rf-services {
      .service-image {
        width: 80px;
      }
    }
  }
}
</style>
