<template>
  <div class="rf-currency">
    <currency-input class="rf-currency-input"
    @focusout="labelUp = false"
    @click="labelUp = !labelUp"
    v-model="currencyValue"
    :style="{ border: `1px solid ${color}`, backgroundColor: background, color }" />
    <label class="rf-currency-label"
    :style="{ color: labelColor }"
    :class="{ 'stay-on-top': labelUp || value }"
    v-if="label">{{$t(label)}}</label>
  </div>
</template>

<script>
export default {
  name: 'RFCurrencyInput',
  props: {
    label: String,
    background: String,
    color: {
      type: String,
      default: '#24425B',
    },
    labelColor: {
      type: String,
      default: '#000',
    },
    value: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currencyValue: null,
      labelUp: false,
    }
  },
  beforeMount() {
    if (this.value) {
      this.currencyValue = this.value
    }
  },
  watch: {
    currencyValue: {
      handler() {
        this.$emit('input', this.currencyValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-currency {
  margin-top: 20px;
  position: relative;

  .rf-currency-input {
    @include font-style($montserrat, 'medium', $font-14);
    width: 100%;
    position: relative;
    outline: none;
    padding: 5px 15px;
    background: transparent;
    z-index: 1;
    width: 100%;
    height: 32px;
    border: none;
  }
  .rf-currency-label {
    @include font-style($montserrat, 'medium', $font-14);
    position: absolute;
    top: 7px;
    left: 15px;
    background-color: $white;
    margin-bottom: 0;
    padding: 0 2px;
    z-index: 0;
    transition: all .3s;

    &.stay-on-top {
      top: -7.5px;
      font-size: $font-10;
      z-index: 5;
    }
  }
}
</style>